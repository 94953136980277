import React from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl, FormattedMessage } from 'react-intl';

import Snackbar from '@cof/graffiti-alley-spray-cans/molecules/Snackbar';

import './IncidentBanner.scss';

const IncidentBanner = injectIntl(({ path }) => {
  return (
    <div id="incident-banner" data-testid="incident-banner">
      <Snackbar id="sign-in-banner-warning" type="warning" path={path}>
        <FormattedMessage id="incident.sign-in" />
      </Snackbar>
    </div>
  );
});

IncidentBanner.propTypes = {
  intl: intlShape,
  path: PropTypes.string,
};

export default IncidentBanner;
