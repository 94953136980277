import React, { useState } from 'react';
import { navigate } from 'gatsby';
import { FormattedMessage, injectIntl } from 'react-intl';
import HeroBanner from '@cof/graffiti-alley-spray-cans/molecules/HeroBanner';
import StickyBar from '@cof/graffiti-alley-spray-cans/molecules/StickyBar';
import Link from '@cof/graffiti-alley-spray-cans/atoms/Link';
import ModalLink from '@cof/graffiti-alley-spray-cans/molecules/ModalLink';
import { Tabs, TabTitle, TabPanel } from '@cof/graffiti-alley-spray-cans/molecules/Tabs';

import { ALL_BRANDS, BUILD_BRANDS, REWARDS_BRANDS } from '../../lib/constants';
import CardItem from '../../components/CardItem';
import Layout from '../../components/Layout';
import SEO from '../../components/SEO/SEO';
import LegalContent from '../../components/ModalContent/LegalModalContent';
import { superScript } from '../../lib/utils';

import './compare.scss';
const validFilters = ['all', 'rewards', 'build'];

const ComparePage = injectIntl(({ intl, location }) => {
  const [activeFilter, setActiveFilter] = useState('all');
  const setFilter = (newFilter) => {
    if (newFilter && newFilter !== activeFilter && validFilters.includes(newFilter)) {
      setActiveFilter(newFilter);
    }
  };
  const getQueryParamsWithFilter = (filter) => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set('filter', filter);
    return queryParams.toString();
  };

  React.useEffect(() => {
    setFilter(new URLSearchParams(location.search).get('filter'));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const changeHandler = (newFilter) => {
    if (activeFilter !== newFilter) {
      navigate(`/credit-cards/compare/${intl.locale === 'fr' ? 'fr/' : ''}?${getQueryParamsWithFilter(newFilter)}#`, {
        replace: true,
      });
      setFilter(newFilter);
    }
  };

  const tabOnClick = (filter) => () => changeHandler(filter);
  const tabOnKeyDown = (event, nextTitleProps) => changeHandler(nextTitleProps.tabTitleName);
  const quickcheckBannerContent = (
    <div className="quickcheck-hero-content">
      <p className="as-h1">
        <FormattedMessage
          id="hero.compare-page.quickcheck"
          values={{ rball: superScript('rball'), star: superScript('star'), MC: <sup>MC</sup>, one: <sup>1</sup> }}
        />
      </p>
      <div className="qk-banner-button">
        <Link to="/quickcheck/" className="action button banner quickcheck-button">
          <FormattedMessage id="hero.compare-page.quickcheck-cta-button" />
        </Link>
      </div>
    </div>
  );

  return (
    <Layout pageName="compare-page">
      <div className={`product-list-page-content-wrapper ${activeFilter === 'all' ? 'see-all-cards' : ''}`} role="none">
        <HeroBanner backgroundContentClass={`product-list-hero hero-${activeFilter}`} mobileFriendly>
          <div className="page-content-wrapper" data-for="product-list-hero">
            <section id="product-list-header" className=" page-content">
              <div id="product-list-header-text" className="plain-text">
                <FormattedMessage id="hero.compare-page.mobile" />
              </div>
            </section>
            <section id="product-list-hero" className=" page-content">
              <div id="product-list-hero-content" className="container-body">
                <section id={`product-list-hero-${activeFilter}`} className={`product-list-hero-${activeFilter}`}>
                  {quickcheckBannerContent}
                </section>
              </div>
            </section>
          </div>
        </HeroBanner>
        <section id="product-list-filter" className=" page-content">
          <header>
            <div id="product-list-filter-title" className="plain-text">
              <h1 className="show-for-sr">
                <FormattedMessage id="pages.compare.hidden-heading" />
              </h1>
              <p className="as-h1">
                <FormattedMessage id="pages.compare.filter-title" />
              </p>
            </div>
            <div className="quickcheck-mobile-title">{quickcheckBannerContent}</div>
          </header>

          <div id="product-list-filter-content" className="container-body">
            <Tabs>
              <TabTitle
                tabTitleName="all"
                triggers={ALL_BRANDS}
                onClick={tabOnClick('all')}
                onKeyDown={tabOnKeyDown}
                selected={activeFilter === 'all'}
              >
                <i className="oi-credit-card outline" />
                <FormattedMessage id="cards.highlights.see-all" />
              </TabTitle>

              <TabTitle
                tabTitleName="rewards"
                triggers={REWARDS_BRANDS}
                onClick={tabOnClick('rewards')}
                onKeyDown={tabOnKeyDown}
                selected={activeFilter === 'rewards'}
              >
                <i className="oi-gift outline" />
                <FormattedMessage id="cards.highlights.get-rewards" />
              </TabTitle>

              <TabTitle
                tabTitleName="build"
                triggers={BUILD_BRANDS}
                onClick={tabOnClick('build')}
                onKeyDown={tabOnKeyDown}
                selected={activeFilter === 'build'}
              >
                <i className="oi-trophy outline" />
                <FormattedMessage id="cards.highlights.build-or-rebuild" />
              </TabTitle>

              <TabPanel id="gm">
                <CardItem
                  brand="gm"
                  compareFilter={activeFilter}
                  viewDetailsUrl={`/credit-cards/guaranteed-mastercard/?${getQueryParamsWithFilter(activeFilter)}`}
                  staticCardArt
                />
              </TabPanel>
              <TabPanel id="gsm">
                <CardItem
                  brand="gsm"
                  compareFilter={activeFilter}
                  viewDetailsUrl={`/credit-cards/guaranteed-secured-mastercard2/?${getQueryParamsWithFilter(
                    activeFilter,
                  )}`}
                  staticCardArt
                />
              </TabPanel>
              <TabPanel id="nfsrm">
                <CardItem
                  brand="nfsrm"
                  compareFilter={activeFilter}
                  viewDetailsUrl={`/credit-cards/smart-rewards-mastercard/?${getQueryParamsWithFilter(activeFilter)}`}
                  staticCardArt
                  showNewBubble={true}
                  showNoReviews={true}
                />
              </TabPanel>
              <TabPanel id="atp">
                <CardItem
                  brand="atp"
                  compareFilter={activeFilter}
                  viewDetailsUrl={`/credit-cards/aspire-travel-platinum/?${getQueryParamsWithFilter(activeFilter)}`}
                  staticCardArt
                />
              </TabPanel>
              <TabPanel id="acp">
                <CardItem
                  brand="acp"
                  compareFilter={activeFilter}
                  viewDetailsUrl={`/credit-cards/aspire-cash-platinum/?${getQueryParamsWithFilter(activeFilter)}`}
                  staticCardArt
                />
              </TabPanel>
            </Tabs>
          </div>
        </section>
        <section id="product-list-legal" className=" product-list-legal" data-for="product-list-legal">
          <ModalLink
            target={LegalContent}
            className="product-list-legal-link"
            title=" "
            linkIcon={false}
            intl={intl}
            size="large"
          >
            <FormattedMessage id="navfooter.legal.legal-updated" />
          </ModalLink>
        </section>
        <StickyBar contentPosition="center" isVerticalDisplayinSmallScreen={false} stickybarPosition="bottom">
          <p className="quickcheck-cta-text">
            <FormattedMessage id="pages.compare.quickcheck-sticky-cta" values={{ one: superScript('one') }} />
          </p>
          <div className="stickybar-button">
            <Link to="/quickcheck/" className="action button sticky quickcheck-button">
              <FormattedMessage id="hero.compare-page.quickcheck-cta-button" />
            </Link>
          </div>
        </StickyBar>
      </div>
    </Layout>
  );
});

export default ComparePage;

/* eslint-disable react/prop-types */
export const Head = ({ pageContext }) => (
  <SEO titleKey="pages.compare.page-title" descriptionKey="pages.compare.page-desc" intl={pageContext.intl} />
);
/* eslint-enable react/prop-types */
